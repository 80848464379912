import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import notFound from '../assets/not-found.svg';

const StyledLink = styled(Link)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const NotFound = () => (
  <StyledLink to="/" className="inset-0">
    <img src={notFound} alt="404 Not Found" className="max-w-md mx-10" />
  </StyledLink>
);

export default NotFound;
