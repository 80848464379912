import { decodeQuery } from '@zedoc/url';
import { getNavigatorLanguages, getPreferredLanguage } from '@zedoc/i18n';
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { resolveOnSelector } from '@zedoc/selectors';
import { setUserLanguage, getUserLanguage } from '../store/preferences';

const ENGLISH_VALUE = 'en';

// eslint-disable-next-line no-underscore-dangle
const isReady = (state) => state && state._persist && state._persist.rehydrated;

export const supportedLanguages = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(',')
  : [ENGLISH_VALUE];

const supportedNamespaces = ['common'];

let initialLanguages;
if (window.location.search) {
  const query = decodeQuery(window.location.search);
  if (query.i18n) {
    initialLanguages = query.i18n.split(',');
  }
}

export const initI18next = (store) => {
  // see https://www.i18next.com/misc/creating-own-plugins#languagedetector
  const languageDetector = {
    type: 'languageDetector',
    async: true,
    init: () => {},
    detect: (callback) => {
      resolveOnSelector(store, isReady)
        .then(() => {
          const language = getUserLanguage(store.getState());
          if (language) {
            return [language];
          }
          return getNavigatorLanguages();
        })
        .then((languages) => {
          if (initialLanguages && initialLanguages.length > 0) {
            callback(
              getPreferredLanguage(
                languages,
                initialLanguages,
                initialLanguages[0],
              ),
            );
          } else {
            callback(languages[0]);
          }
        })
        .catch((err) => {
          console.error(err);
          callback(null);
        });
    },
    cacheUserLanguage: (language) => {
      store.dispatch(setUserLanguage(language));
    },
  };

  i18next.use(Backend).use(initReactI18next);
  i18next.use(languageDetector);
  i18next.init(
    {
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      interpolation: {
        escapeValue: false,
      },
      nonExplicitWhitelist: true,
      whitelist: supportedLanguages,
      ns: supportedNamespaces,
      fallbackLng: ENGLISH_VALUE,
      defaultNS: 'common',
      // debug: true,
    },
    // eslint-disable-next-line no-console
    (err) => err && console.error(err),
  );
  return i18next;
};
