import { createSlice } from '@reduxjs/toolkit';
import { createGetAtKey } from '@zedoc/selectors';
import parseQuestionnaire from '../utils/parseQuestionnaire';

const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState: {
    isQuestionReady: true,
    direction: 'inline-end',
    rawQuestionnaire: null,
    rawTranslation: null,
    questionId: null,
  },
  reducers: {
    setRawQuestionnaire: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.rawQuestionnaire = action.payload;
    },
    setRawTranslation: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.rawTranslation = action.payload;
    },
    setQuestionId: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.questionId = action.payload;
    },
    setIsQuestionReady: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isQuestionReady = action.payload;
    },
    setDirection: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.direction = action.payload;
    },
  },
});

export const {
  setRawQuestionnaire,
  setRawTranslation,
  setQuestionId,
  setIsQuestionReady,
  setShouldGoThroughMissingQuestions,
  setDirection,
} = formBuilderSlice.actions;

export const selectIsQuestionReady = createGetAtKey(
  'formBuilder.isQuestionReady',
);
export const selectDirection = createGetAtKey('formBuilder.direction');

export const selectRawQuestionnaire = createGetAtKey(
  'formBuilder.rawQuestionnaire',
);
export const selectRawTranslation = createGetAtKey(
  'formBuilder.rawTranslation',
);
export const selectQuestionId = createGetAtKey('formBuilder.questionId');
export const selectTranslatedQuestionnaire = parseQuestionnaire(
  selectRawQuestionnaire,
  selectRawTranslation,
);

export default formBuilderSlice.reducer;
