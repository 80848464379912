import { sha256base64url } from '@zedoc/sha256';
import Random from '@zedoc/random';

function getSecretChallenge() {
  const secret = Random.secret();
  const secretChallenge = sha256base64url(secret);
  return {
    secret,
    secretChallenge,
  };
}

export default getSecretChallenge;
