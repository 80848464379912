import notification from './notification';

export const createNotifyError = (message) => (err) => {
  const key = `error_${Date.now()}`;
  let meta = {};
  let msg;
  if (typeof err === 'string') {
    msg = err;
  } else if (err instanceof Error) {
    msg = err.reason || err.toString();
    meta = {
      stack: err.stack,
    };
  } else if (err && typeof err === 'object' && err.message) {
    msg = err.message;
    meta = err;
  } else {
    msg = 'Unknown error';
  }
  if (typeof message === 'string') {
    msg = message;
  } else if (typeof message === 'function') {
    msg = message(msg);
  }
  notification.error({
    key,
    message: msg,
    duration: 0,
  });
  console.error(msg, meta);
};

export const createNotifySuccess =
  (message) =>
  (...args) => {
    const key = `success_${Date.now()}`;
    let msg;
    if (typeof message === 'function') {
      msg = message(...args);
    } else if (message) {
      msg = message.toString();
    } else {
      msg = 'Success';
    }
    notification.success({
      key,
      message: msg,
    });
    console.log(msg, {
      args,
    });
  };
