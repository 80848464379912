import isPlainObject from 'lodash/isPlainObject';
import { createSelector } from 'reselect';
import Questionnaire, {
  QuestionnaireTranslation,
  QUESTION_TYPE__EMPTY,
} from '@zedoc/questionnaire';
import { toSelector } from '@zedoc/selectors';

export const addFinishQuestion = (questionnaire) => ({
  ...questionnaire,
  questions: [
    ...(questionnaire.questions || []),
    {
      id: '~finish',
      type: QUESTION_TYPE__EMPTY,
      sectionId: questionnaire.rootSectionId,
    },
  ],
});

export const isFinish = (question) => question.id === '~finish';

const parseQuestionnaire = (
  selectRawQuestionnaire,
  selectRawTranslation,
  selectFinalComputations,
) => {
  return createSelector(
    toSelector(selectRawQuestionnaire),
    toSelector(selectRawTranslation),
    toSelector(selectFinalComputations),
    (rawQuestionnaire, rawTranslation, finalComputations) => {
      let questionnaireObject;
      let questionnaire;
      if (isPlainObject(rawQuestionnaire)) {
        questionnaireObject = rawQuestionnaire;
      } else if (typeof rawQuestionnaire === 'string') {
        try {
          questionnaireObject = JSON.parse(rawQuestionnaire);
        } catch (err) {
          // ...
        }
      }
      if (questionnaireObject) {
        const additionalQuestions = [
          {
            id: '~finish',
            type: QUESTION_TYPE__EMPTY,
            sectionId: questionnaireObject.rootSectionId,
          },
          ...Questionnaire.compileFinalComputations(
            questionnaireObject,
            finalComputations,
          ),
        ];
        questionnaire = Questionnaire.createWithAdditionalQuestions(
          questionnaireObject,
          additionalQuestions,
        );
      } else {
        questionnaire = new Questionnaire({});
      }
      let translationObject;
      if (isPlainObject(rawTranslation)) {
        translationObject = rawTranslation;
      } else if (typeof rawTranslation === 'string') {
        try {
          translationObject = JSON.parse(rawTranslation);
        } catch (err) {
          // ...
        }
      }
      if (translationObject) {
        const translation = new QuestionnaireTranslation(translationObject);
        questionnaire = translation.translate(questionnaire);
      }
      return questionnaire;
    },
  );
};

export default parseQuestionnaire;
