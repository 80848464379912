// @ts-check
import merge from 'lodash/merge';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import ObjectBuilder from './ObjectBuilder';

/**
 * @typedef {object} Settings
 * @property {object} public
 * @property {string} [public.commitSha]
 * @property {string} [public.originalCommitSha]
 * @property {number} [public.commitTime]
 * @property {string} [public.branchName]
 * @property {string} [public.tagName]
 * @property {string} [public.patientGraphqlUrl]
 * @property {string} [public.dexcomClientId]
 * @property {string} [public.dexcomClientSecret]
 * @property {string} [public.patientUrl]
 * @property {string} [public.patientAuthLoginUrl]
 * @property {string} [public.patientAuthTokenUrl]
 * @property {number} [public.patientRefreshTokenDelaySeconds]
 * @property {number} [public.patientMaxAllowedInactivitySeconds]
 * @property {string} [public.formBuilderUrl]
 * @property {string} [public.formBuilderAuthLoginUrl]
 * @property {string} [public.appName]
 */

/**
 * @param {unknown} obj
 * @returns {Partial<Settings>}
 */
const cleanSettings = (obj) => {
  if (!isObject(obj) || !('public' in obj) || !isObject(obj.public)) {
    return {};
  }
  const data = /** @type {Record<string, unknown>} */ (obj.public);
  return {
    public: new ObjectBuilder(/** @type {Settings['public']} */ ({}))
      .setSafely(isString, 'commitSha', data.commitSha)
      .setSafely(isNumber, 'commitTime', data.commitTime)
      .setSafely(isString, 'branchName', data.branchName)
      .setSafely(isString, 'tagName', data.tagName)
      .setSafely(isString, 'patientGraphqlUrl', data.patientGraphqlUrl)
      .setSafely(isString, 'dexcomClientId', data.dexcomClientId)
      .setSafely(isString, 'dexcomClientSecret', data.dexcomClientSecret)
      .setSafely(isString, 'patientUrl', data.patientUrl)
      .setSafely(isString, 'patientAuthLoginUrl', data.patientAuthLoginUrl)
      .setSafely(isString, 'patientAuthTokenUrl', data.patientAuthTokenUrl)
      .setSafely(
        isNumber,
        'patientRefreshTokenDelaySeconds',
        data.patientRefreshTokenDelaySeconds,
      )
      .setSafely(
        isNumber,
        'patientMaxAllowedInactivitySeconds',
        data.patientMaxAllowedInactivitySeconds,
      )
      .setSafely(isString, 'formBuilderUrl', data.formBuilderUrl)
      .setSafely(
        isString,
        'formBuilderAuthLoginUrl',
        data.formBuilderAuthLoginUrl,
      )
      .setSafely(isString, 'appName', data.appName)
      .get(),
  };
};

/** @type {Settings} */
const settings = {
  public: {},
};

/**
 * @param {string} jsonString
 */
const parseAndMergeSettings = (jsonString) => {
  if (!jsonString) {
    // it's not an error, settings were simply not provided
    return;
  }
  let parsedSettings;
  try {
    parsedSettings = JSON.parse(jsonString);
  } catch (err) {
    throw new Error(`Settings string is not a valid JSON, got: ${jsonString}`);
  }
  if (parsedSettings) {
    merge(settings, cleanSettings(parsedSettings));
  }
};

// These settings will usually be provided at build stage. In case of react apps,
// it's quite important to ensure that proper commitSha is there.
if (process.env.REACT_APP_SETTINGS) {
  parseAndMergeSettings(process.env.REACT_APP_SETTINGS);
}

if (process.env.REACT_APP_SETTINGS_LOCAL) {
  parseAndMergeSettings(process.env.REACT_APP_SETTINGS_LOCAL);
}

// These settings are deployment specific and should be injected during
// the front-end server startup by replacing 'PRODUCTION_SETTINGS_PLACEHOLDER'
// literal with a proper json string.
if (process.env.NODE_ENV === 'production') {
  const settingsString = 'PRODUCTION_SETTINGS_PLACEHOLDER';
  if (typeof settingsString !== 'string') {
    throw new Error(
      'Production settings should be encoded in a form of json string',
    );
  } else if (
    settingsString.toLowerCase() !== 'production_settings_placeholder'
  ) {
    // NOTE: Checking against lowercase version to reduce the risk of injection happening twice
    parseAndMergeSettings(settingsString);
  }
}

export default settings;
