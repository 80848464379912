export const ACTION_LOGIN = '@TOKEN/LOGIN';
export const ACTION_LOGOUT = '@TOKEN/LOGOUT';
export const ACTION_EXCHANGE = '@TOKEN/EXCHANGE';
export const ACTION_REFRESH = '@TOKEN/REFRESH';
export const ACTION_UPDATE = '@TOKEN/UPDATE';
export const ACTION_REPLACE = '@TOKEN/REPLACE';
export const ACTION_FLUSH = '@TOKEN/FLUSH';

/**
 * @param {object} payload
 * @param {string} [payload.authJwt]
 * @param {string} [payload.secret]
 * @param {string} [payload.lastActivityTs]
 */
export const login = ({ authJwt, secret, lastActivityTs } = {}) => {
  const payload = {};
  if (authJwt) {
    payload.authJwt = authJwt;
  }
  if (secret) {
    payload.secret = secret;
  }
  if (lastActivityTs) {
    payload.lastActivityTs = lastActivityTs;
  }
  return {
    type: ACTION_LOGIN,
    payload,
  };
};

export const logout = () => ({
  type: ACTION_LOGOUT,
});

/**
 * @param {object} payload
 * @param {string} payload.exchangeJwt
 */
export const exchange = ({ exchangeJwt }) => ({
  type: ACTION_EXCHANGE,
  payload: {
    exchangeJwt,
  },
});

export const refresh = () => ({
  type: ACTION_REFRESH,
});

/**
 * @param {object} payload
 * @param {string} [payload.sessionJwt]
 * @param {string} [payload.refreshJwt]
 */
export const update = ({ sessionJwt, refreshJwt }) => {
  const payload = {};
  if (sessionJwt) {
    payload.sessionJwt = sessionJwt;
  }
  if (refreshJwt) {
    payload.refreshJwt = refreshJwt;
  }
  return {
    type: ACTION_UPDATE,
    payload,
  };
};

/**
 * @param {object} payload
 */
export const replace = (payload) => {
  return {
    type: ACTION_REPLACE,
    payload,
  };
};

export const flush = () => ({
  type: ACTION_FLUSH,
});
